import { Component } from '@angular/core';
import { IComboBase } from '../interfaces/base/base.interface';
// import { VacationService } from './services/vacation.service';
import { VacationRequestDTO } from '../interfaces/vacation.interface';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalLoadingComponent } from '../shared/modals/modal-loading/modal-loading.component';
import { ModalSuccessComponent } from '../shared/modals/modal-success/modal-success.component';
import { ModalRejectComponent } from '../shared/modals/modal-reject/modal-reject.component';
import { Router } from '@angular/router';
import { monthOptions } from '../utils/helpers';
// import { ModalInfoComponent } from './components/modal-info/modal-info.component';

@Component({
  selector: 'app-salary-advance',
  templateUrl: './salary-advance.component.html',
  styleUrls: ['./salary-advance.component.scss'],
})
export class SalaryAdvanceComponent {
  selectedAdvance: any;
  selectedMonth: any;
  filteredVacationTypes: IComboBase[] = [];
  monthOptions: IComboBase[] = monthOptions;
  filteredMonthOptions: IComboBase[] = [];
  filteredOptions: IComboBase[] = [];

  advance: IComboBase[] = [
    { key: '1', text: 'Mensualidad' },
    { key: '2', text: 'Bono vacacional' },
  ];

  month: IComboBase[] = monthOptions;

  constructor(
    // private vacationService: VacationService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  filterOptions(event: any, type: string) {
    const query = event.query.toLowerCase();
    let options: IComboBase[] = [];

    switch (type) {
      case 'advance':
        options = this.advance;
        break;
      case 'month':
        options = this.month;
        break;

      default:
        console.error('Tipo no reconocido:', type);
    }
    this.filteredOptions = options.filter((option) =>
      option.text.toLowerCase().includes(query)
    );
  }

  onChangeInput(event: any, type: string) {
    // this.hasInteracted[type] = true;
    switch (type) {
      case 'advance':
        this.selectedAdvance = null;
        console.log('advance: ', this.selectedAdvance);
        break;
      case 'month':
        this.selectedMonth = null;
        console.log('month: ', this.selectedMonth);
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
    // this.validateForm();
  }

  onSelect(event: any, type: string) {
    // this.hasInteracted[type] = true;

    switch (type) {
      //   case 'empresa':
      //     this.selectedEmpresa = event.value ?? null;
      //     console.log('Empresa seleccionada:', this.selectedEmpresa);
      //     this.companyId = this.selectedEmpresa?.key!;

      //     this.selectedColaborador = null;

      //     if (this.companyId) {
      //       this.employeeService.comboEmployee(this.companyId).subscribe({
      //         next: (data) => {
      //           this.colaboradores = data;
      //         },
      //         error: (err) => {
      //           console.error('Error al obtener la lista de colaboradores:', err);
      //         },
      //       });
      //     }

      //     break;
      case 'advance':
        this.selectedAdvance = event.value ?? null;
        console.log('Avance seleccionado:', this.selectedAdvance);
        break;
      case 'month':
        this.selectedMonth = event.value ?? null;
        console.log('Mes seleccionado:', this.selectedMonth);
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }

    // this.validateForm();
  }

  //   onSelect(event: any): void {
  //     this.selectedAdvance = event.value ?? null;
  //     // this.selectedOptionKey = this.selectedVacationType?.key || null;
  //     // console.log('Tipo de vacaciones seleccionada:', this.selectedOptionKey);
  //     // this.validateForm();
  //   }
}
