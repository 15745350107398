<div class="vacation-request-container p-fluid">
  <!-- <h2 class="title">Especifica el periodo</h2>
  <p>Solicita un adelanto de tu sueldo</p> -->
  <app-page-header
    [title]="'Adelanto de sueldo'"
    [subtitle]="'Solicita un adelanto de tu sueldo'"
  ></app-page-header>

  <div class=""></div>
  <!-- Adelanto -->
  <div class="p-field-autocomplete">
    <label for="advance">Adelanto de</label>
    <p-autoComplete
      id="advance"
      [(ngModel)]="selectedAdvance"
      [suggestions]="filteredOptions"
      (completeMethod)="filterOptions($event, 'advance')"
      (input)="onChangeInput($event, 'advance')"
      [dropdown]="true"
      [forceSelection]="true"
      placeholder="Seleccione el tipo"
      (onSelect)="onSelect($event, 'advance')"
      [style]="{ width: '100%' }"
      [field]="'text'"
    ></p-autoComplete>
  </div>

  <!-- Mensualidad -->
  <div class="p-field-autocomplete">
    <label for="month">Del mes de</label>
    <p-autoComplete
      id="month"
      [(ngModel)]="selectedMonth"
      [suggestions]="filteredOptions"
      (completeMethod)="filterOptions($event, 'month')"
      (input)="onChangeInput($event, 'month')"
      [dropdown]="true"
      [forceSelection]="true"
      placeholder="Seleccione el mes"
      (onSelect)="onSelect($event, 'month')"
      [style]="{ width: '100%' }"
      [field]="'text'"
    ></p-autoComplete>
  </div>
</div>
